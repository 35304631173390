import React, { useState } from "react";
import { storage } from "../config/firebase";
import { getDatabase, ref as dbRef, set } from "firebase/database";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { Snackbar, Alert } from "@mui/material";
export const GrammarUpload = () => {
  const [file, setFile] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // 'success', 'error', 'warning', 'info'
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (file && name && description) {
      const storageRef = ref(storage, `grammar/${file.name}`);
      const database = getDatabase();
      setIsLoading(true);

      try {
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);

        const metadataRef = dbRef(database, `grammar/${name}`);
        await set(metadataRef, {
          name,
          description,
          url: downloadURL,
        });

        // Show success message
        showSnackbar(
          "File uploaded and metadata saved successfully!",
          "success"
        );
        setFile(null);
        setName("");
        setDescription("");
      } catch (error) {
        console.error("Upload failed:", error);
        showSnackbar("Upload failed. Please try again.", "error");
      } finally {
        setIsLoading(false);
      }
    } else {
      showSnackbar("Please fill all fields and select a file.", "warning");
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };
  return (
    <div>
      <Box
        sx={{
          // width: 300,
          padding: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          marginRight: 4,
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{ color: "#1E3E62", marginBottom: 3 }}
        >
          Upload a PDF for Grammar Section
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            backgroundColor: "#fff",
            padding: 3,
            borderRadius: 2,
            boxShadow: 3,
            width: "100%",
            maxWidth: 400,
          }}
        >
          <TextField
            label="Enter Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
          />
          <TextField
            label="Enter Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            multiline
            rows={3}
            fullWidth
          />
          <TextField
            type="file"
            inputProps={{ accept: "application/pdf" }}
            onChange={handleFileChange}
            fullWidth
          />
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#0f2c44",
              "&:hover": { backgroundColor: "#1E3E62" },
            }}
            onClick={handleUpload}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={24} sx={{ color: "#fff" }} />
            ) : (
              "Upload PDF"
            )}
          </Button>
        </Box>
      </Box>
      {/* Snackbar for Alerts */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default GrammarUpload;
