import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import "./About.css"; // Import your CSS file for styles
import { BoltOutlined } from "@mui/icons-material";

export const About = () => {
  const [from_name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [from_email, setEmail] = useState("");
    const [isAnonymous, setIsAnonymous] = useState(true);
    
    
  useEffect(() => {
    emailjs.init("jScY0PatqyMZlWZkg"); // Initialize EmailJS with your user ID
  }, []);
  
  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name,
      message,
      from_email,
    };

    emailjs
      .send("service_dbugm7j", "template_0elmihe", templateParams)
      .then((response) => {
        console.log("Email successfully sent!", response.status, response.text);
        alert("Email sent successfully!");

        // Clear the form fields
        setName("");
        setMessage("");
        setEmail("");
      })
      .catch((err) => {
        console.error("Failed to send email. Error: ", err);
        alert("Failed to send email. Please try again.");
      });
  };
  const handleToggleAnonymous = () => {
    setIsAnonymous(!isAnonymous);
    if (!isAnonymous) {
      setEmail(''); // Set email to anonymous
    } else {
      setEmail(''); // Clear the email input when it's not anonymous
    }
  };

  return (
    <div className="about-container px-6 py-10 text-gray-800">
      <div className="text-center mb-8">
        <h4 className="text-3xl font-bold mb-2">
          Embrace the Himalayan Tongues
        </h4>
        <h5 className="text-lg font-medium text-gray-600">
          Connect with Pahadi culture, one word at a time.
        </h5>
      </div>

      <div className="flex flex-col lg:flex-row justify-between gap-8">
      
{/* Section 1 */}
<div className="bg-gray-100 p-6 rounded-lg lg:w-1/2">
  <p
    className="text-sm sm:text-base lg:text-lg text-center leading-8 text-gray-800"
    style={{ fontFamily: "'Noto Sans Devanagari', sans-serif", fontStyle: 'normal' }}
  >
    कुमाउँनी भऻसक इतिहाश आज या बेईक न्हाति। कम है कम आठ शौ बरशन है याँक
    राजदरबाराक मंतरि ये भऻस मजि अपुण पत्तर हौर लुकुर टीप-टाप ल्यखन्यान। 
    मुख्य रुपैल चंद राजानाक समैक मस्त दानपत्तर हौर लुकुर लेख हमुन कणि
    कुमाऊँ मजि ये भऻसक एकमुँया पर्योगाक बार मजि बतुनान। किन्तु ये भऻसक
    इतिहाश मांतर दरबारन लैक बन्दोर न्हाति. ३०० बरशन बटि याँ रूनेर
    विद्वान मैस ये भऻस मजि शाहित्यक रचना लै कर्न्यान। स्व० रामभद्र
    त्रिपाठी ज्यूल सन् १७२८ मजि सबुहै पैंलि शंशकिर्तैकि 'चाणक्य निती'
    रचनाक अनुबाद कुमाउँनी मजि करछ्य। वीक पछा, एकमुँया रुपैल, कुमाऊँ मजि
    बिद्बान झणीन लै शाहित्योक लेखन हौर प्रकासन कर रऻखौ। किन्तु अछ्यालाक
    मैस अपुण भऻस बुलाण मजि झैम्प चितुनान। यस मक्कड़ भऻसाक उप्चौ लिजी हम
    सबूल यकबट्टीबेर काव-बिकाव करण पड़लि, तभै यो भऻस अपुण असल श्वरूप मजि
    अघिल ऊनेर छानना लिजी बचि रौली। यो ऑनलाइन आँखरकोस लै भऻसाक उप्चौ लिजी
    हमरि नऻनि काव-बिकाव छ, ज कुमाउँनी भऻस शिकना लिजी मस्त काम लागोल।
    <br /><strong>जय उत्तराखंड, जय देवभूमि कूर्माञ्चल</strong>
  </p>
</div>

{/* Section 2 */}
<div className="bg-gray-100 p-6 rounded-lg lg:w-1/2">
  <p
    className="text-sm sm:text-base lg:text-lg text-center leading-8 text-gray-800"
    style={{ fontFamily: "sans-serif", fontStyle: 'normal' }}
  >
    Pahadi regions of India have a range of cultural languages, each
    with its unique vocabulary, grammar, syntax, and pronunciation.
    Unfortunately, there has been a growing negligence towards these
    languages, both by the local communities and by state and central
    government. These languages are native to the region and are vital
    to the region’s heritage, having been spoken long before the arrival
    of languages they are being labeled as "dialects". It is essential
    to respect and preserve the languages that our ancestors nurtured
    and passed down through generations. To address this challenge, this
    website is built to promote one of the many languages spoken in
    Uttarakhand, Kumaoni. This online dictionary is useful for the Kumaoni people how wish to improve there exposure to the language and helps in learning and practicing the language. 
   <br/> Hail Uttarakhand, Hail Devbhoomi Kurmanchal ! 
  </p>
</div>
</div>
      <div className="contact-section mt-12 bg-white shadow-md p-6 rounded-md">
        <h4 className="text-2xl font-bold mb-4">Contact Us</h4>
        <p className="text-md">
          If you want to contribute to this initiative, contact me at
          <a
            className="contact-link text-grey-500 font-medium ml-1"
            href="mailto:kunwarisha9@gmail.com"
          >
            kunwarisha9@gmail.com
          </a>
          .
        </p>
        <p className="text-md">Your feedback is important to us! Feel free to share your thoughts, suggestions, or reviews. You can send your message anonymously, and we assure you that your privacy will be respected. We appreciate your input and look forward to hearing from you!</p>
        {/* <p className=" text-md mb-6">
          We would love to hear from you! Please send us your reviews or
          messages.
        </p> */}
	 {/* Anonymous Slider */}
      <div className="flex items-center space-x-3 mt-4">
        <label className="text-md text-gray-700">Send Anonymously</label>
        <label className="inline-flex items-center cursor-pointer">
          <span className="mr-2 text-gray-500">No</span>
          <div
            className={`relative inline-block  rounded-full w-10 mr-2 align-middle select-none transition duration-200 ease-in ${
              isAnonymous ? 'bg-green-500' : 'bg-gray-300'
            }`}
          >
            <input
              type="checkbox"
              className="toggle-checkbox absolute block w-1 h-1 rounded-full bg-white cursor-pointer opacity-0"
              checked={isAnonymous}
              onChange={handleToggleAnonymous}
            />
            <span
              className={`toggle-label block overflow-hidden h-5 w-5 rounded-full bg-white shadow-md cursor-pointer `}
              style={{
                transform: isAnonymous ? 'translateX(100%)' : 'translateX(0%)',
                transition: 'transform 0.2s',
              }}
            ></span>
          </div>
          <span className="ml-2 text-gray-500">Yes</span>
        </label>
      </div>
        <form onSubmit={handleSubmit} className="space-y-4 mt-6">
          <input
            type="text"
            placeholder="Your Name"
            value={from_name}
            onChange={(e) => setName(e.target.value)}
            required
            className="w-full border border-gray-300 p-3 rounded-md focus:ring-2 focus:ring-grey-700 focus:outline-none"
          />
          <input
            type="email"
            placeholder="Your Email"
            value={from_email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full border border-gray-300 p-3 rounded-md focus:ring-2 focus:ring-grey-700 focus:outline-none"
	    disabled={isAnonymous} // Disable email input if sending anonymously
          />
          <textarea
            placeholder="Your Message"
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            className="w-full border border-gray-300 p-3 rounded-md focus:ring-2 focus:ring-grey-700 focus:outline-none"
          />
          <button
            type="submit"
            className="text-white py-2 px-6 rounded-md transition duration-300"
          >
            Send
          </button>
        </form>
      </div>
    </div>
  );
};
