import React, { useState, useEffect } from 'react';
import { getDatabase, ref, get } from 'firebase/database';
import { auth } from '../config/firebase';
import { Snackbar, Alert, Card, CardContent, Typography, Button, Link, CircularProgress  } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const SavedWordsPage = () => {
  const [savedWords, setSavedWords] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [isSignedIn, setIsSignedIn] = useState(false); // To track if the user is logged in
  const [loading, setLoading] = useState(true); // Track loading state
  const navigate = useNavigate();

  // Check if the user is logged in using onAuthStateChanged
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsSignedIn(!!user);
      setLoading(false); 
    });

    return () => unsubscribe(); // Clean up the subscription on component unmount
  }, []);

  // Fetch saved words when the user is logged in
  useEffect(() => {
    if (!isSignedIn) {
      setLoading(false); // Stop loading if user is not signed in
      return;
    }


    const fetchSavedWords = async () => {
      const user = auth.currentUser;
      if (!user) {
        setErrorMessage('You need to be logged in to view your saved words.');
        setSnackbarSeverity('error');
        setLoading(false); // Stop loading on error
        return;
      }

      const database = getDatabase();
      const favoriteRef = ref(database, `user_favorites/${user.uid}`);
      try {
        const snapshot = await get(favoriteRef);
        if (snapshot.exists()) {
          const favorites = snapshot.val();
          const formattedFavorites = Object.keys(favorites).map((word) => ({
            word,
            translations: favorites[word],
          }));
          setSavedWords(formattedFavorites);
        } else {
          setSavedWords([]); // No saved words
        }
      } catch (error) {
        setErrorMessage('Error fetching saved words.');
        setOpenSnackbar(true);
      } finally {
        setLoading(false); // Stop loading when done
      }
    };

    fetchSavedWords();
  }, [isSignedIn]); // Fetch saved words when login status changes

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h5" gutterBottom>
        Your Saved Words
      </Typography>

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <CircularProgress />
        </div>
      ) : isSignedIn ? (
        <>
          {savedWords.length === 0 ? (
            <Typography variant="body1">You have no saved words.</Typography>
          ) : (
            <div>
              {savedWords.map((item) => (
                <Card key={item.word} style={{ marginBottom: '15px' }}>
                  <CardContent>
                    <Typography variant="h6">{item.word}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      Garhwali: {item.translations.garhwaliTranslation}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Jaunsari: {item.translations.jaunsariTranslation}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Kumaoni: {item.translations.kumauniTranslation}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </div>
          )}
        </>
      ) : (
        <Typography variant="body1">
          You need to be logged in to view your saved words.{' '}
          <Link
            component="button"
            variant="body2"
            onClick={() => navigate('/auth')}
          >
            Log in
          </Link>
        </Typography>
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert severity={errorMessage.includes('Error') ? 'error' : 'success'}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SavedWordsPage;