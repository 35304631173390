import React, { useState, useEffect } from 'react';
import { auth } from '../config/firebase';
import { getDatabase, ref, get, query, limitToFirst, set, remove } from 'firebase/database';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Snackbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  IconButton,
  ExpandMoreIcon,
  CircularProgress,
  Divider,
  Alert,
  Grid,
} from '@mui/material';
import { Helmet } from 'react-helmet';
import { FaVolumeUp } from 'react-icons/fa';

const CardWithTranslations = ({ word,
  //  Garhwali, Jaunsari,
    Kumauni, pronunciation}) => {
  const [isFavorited, setIsFavorited] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  // Calculate the padding based on the number of translations present
  const translationCount = [
    // Garhwali, Jaunsari,
     Kumauni].filter(Boolean).length;
  const paddingValue = translationCount === 1 ? 2 : translationCount === 2 ? 3 : 4;
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')
// Check if the user is logged in
useEffect(() => {
  const unsubscribe = auth.onAuthStateChanged((user) => {
    setIsSignedIn(!!user);
  });

  return () => unsubscribe();

}, []);

const handlePlayAudio = () => {
  if (pronunciation) {
    const audio = new Audio(pronunciation);
    audio.play().catch((error) => {
      console.error('Error playing audio:', error);
    });
  } else {
    console.log('No pronunciation available.');
  }
};

useEffect(() => {
  if (isSignedIn) {
    const checkIfFavorited = async () => {
      try {
        const database = getDatabase();
        const user = auth.currentUser;
        const favoriteRef = ref(database, `user_favorites/${user.uid}/${word}`);
        const snapshot = await get(favoriteRef);
        setIsFavorited(snapshot.exists());
      } catch (error) {
        console.error('Error checking favorite status:', error);
      }
    };
    checkIfFavorited();
  }
}, [isSignedIn, word]);

const handleFavoriteClick = async () => {
  if (isSignedIn) {
    const user = auth.currentUser;
    const database = getDatabase();
    const favoriteRef = ref(database, `user_favorites/${user.uid}/${word}`);
    try {
      if (isFavorited) {
        // Remove from favorites
        setSnackbarMessage(`Removing word from favorites`);
        setSnackbarSeverity('success');
        await remove(favoriteRef);
        setIsFavorited(false);
      } else {
        // Add to favorites with translations
        setSnackbarMessage(`Word added to favorites`);
        setSnackbarSeverity('success');
        await set(favoriteRef, {
          word,
          // garhwaliTranslation: Garhwali || "", // Save the Garhwali translation if it exists
          // jaunsariTranslation: Jaunsari || "", // Save the Jaunsari translation if it exists
          kumauniTranslation: Kumauni || "", // Save the Kumauni translation if it exists
        });
        setIsFavorited(true);
      }
    } catch (error) {
      setSnackbarMessage('Error updating favorite status');
      setSnackbarSeverity('error');
      console.error('Error updating favorite status:', error);
    }
  } else {
    setSnackbarMessage('User must be logged in to save favorites');
    setSnackbarSeverity('error');
    console.log('User must be logged in to save favorites');
  }
  setOpenSnackbar(true); // Show Snackbar message after the operation
};

return (
  <>
<Snackbar
  open={openSnackbar}
  autoHideDuration={3000}
  onClose={() => setOpenSnackbar(false)}
>
  <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity}>
    {snackbarMessage}
  </Alert>
</Snackbar>


<Grid container justifyContent="center" alignItems="center" sx={{ pt: 2 }}>
        <Card sx={{ mb: 1, background: '#f2f2f2', boxShadow: 2, borderRadius: 2, p: 2 }}>
          <CardHeader title={word} sx={{ alignItems: 'center' }} />
          <Grid container spacing={3}>
            {Kumauni && (
              <Grid item xs={12}>
                <Typography align="center">Kumaoni:             {pronunciation && (
                  <IconButton onClick={handlePlayAudio} aria-label="play audio">
                    <FaVolumeUp />
                  </IconButton>
                )} {Kumauni}</Typography>
              </Grid>
            )}
          </Grid>
          <CardActions disableSpacing>
            <IconButton
              aria-label="add to favorites"
              onClick={handleFavoriteClick}
              color={isFavorited ? 'error' : 'default'}
            >
              <FavoriteIcon />
            </IconButton>
          </CardActions>
        </Card>
      </Grid>
  </>
);
};





export const Dictionary = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [page, setPage] = useState(2);
  const limit = 10;
  const [loading, setLoading] = useState(false);
  const [hasMoreResults, setHasMoreResults] = useState(true);
  const [totalEntries, setTotalEntries] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchTotalEntries = async () => {
      try {
        const database = getDatabase();
        const wordsRef = ref(database, 'words');
        const totalEntriesSnapshot = await get(query(wordsRef));
        if (totalEntriesSnapshot.exists()) {
          const totalData = totalEntriesSnapshot.val();
          const totalEntriesCount = Object.keys(totalData).length;
          console.log(totalEntriesCount);
          console.log(totalData);
          setTotalEntries(totalEntriesCount);
        } else {
          console.log('No data found.');
        }
      } catch (error) {
        console.error('Error fetching total entries:', error);
      }
    };
    fetchTotalEntries();
  }, []); 


  useEffect(() => {
    // Inside the fetchPageData function
        const database = getDatabase();
        const wordsRef = ref(database, 'words');
        
      const fetchPageData = async () => {
        try {
          const start = (page - 1) * limit;
          const queryRef = query(wordsRef, limitToFirst(start));
          // limitToFirst(limit)

          console.log('Query:', queryRef);  // Add this line to log the query
          setLoading(true);
          const snapshot = await get(queryRef);

          console.log('Fetching data for page:', page);

          if (snapshot.exists()) {
            const wordData = snapshot.val();
            const allResults = Object.entries(wordData).map(([word, value]) => ({
              word,
              // Garhwali: value.Garhwali || '',
              // Jaunsari: value.Jaunsari || '',
              Kumauni: value.Kumauni || '',
              pronunciation: value.pronunciation ? value.pronunciation.url : '',
            }));
            console.log('Fetched data:', allResults);
            setSearchResults(allResults);
          
            // Check if there are more results
            var tpage = Math.ceil(totalEntries / limit);
            if (page <= tpage) {
              setHasMoreResults(true);
            }
            else {
              console.log('No data found.');
              setHasMoreResults(false);
            }
          } else {
              console.log('No data found.');
              setHasMoreResults(false);
            }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      finally {
        setLoading(false);
      }
    };


        fetchPageData();
  }, [page,totalEntries]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || document.documentElement.scrollTop;

      if (scrollY + window.innerHeight >= document.documentElement.scrollHeight - 100 && !loading && hasMoreResults) {
        handleNextPage();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [loading, hasMoreResults]); // Include loading in the dependency array to prevent multiple calls when loading changes

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <>
      <Box className='p-4 bg-gray-50 rounded-md' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <h7 className='text-[26px] font-semibold text-gray-800' >
          Total Words: {totalEntries}
        </h7>
        <Button

          variant="contained"
          sx={{ backgroundColor: '#0f2c44', '&:hover': { backgroundColor: '#1E3E62' } }}
          onClick={() => navigate('/savedwords')}
 
        >
          View Saved Words
        </Button>
      </Box>
  
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        {searchResults.length === 0 && !loading && (
          <Typography variant="h6" align="center">
            No results found.
          </Typography>
        )}
  
        {searchResults.map((result) => (
          <CardWithTranslations key={result.word} {...result} />
        ))}
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          {!hasMoreResults && <Divider />}
          {hasMoreResults && <CircularProgress />}
        </Box>
      </Box>
    </>
  );
};  