import React, { useState } from "react";
import { ReactMic } from "react-mic";
import AudioTimer from "./AudioTimer";

const ReactRecorder = ({ onSave }) => {
  const [isRunning, setIsRunning] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [voice, setVoice] = useState(false);
  const [recordBlobLink, setRecordBlobLink] = useState(null);

  const onStop = (recordedBlob) => {
    console.log("Recorded Blob:", recordedBlob); // Debugging
    setRecordBlobLink(recordedBlob.blobURL); // Save the audio URL for playback
    setIsRunning(false); // Stop the timer
    setVoice(false); // Stop recording

    if (onSave) {
      onSave(recordedBlob); // Pass the blob to the parent
    }
  };

  const startHandle = () => {
    setElapsedTime(0); // Reset the timer
    setIsRunning(true); // Start the timer
    setVoice(true); // Start recording
  };

  const stopHandle = () => {
    setIsRunning(false); // Stop the timer
    setVoice(false); // Stop recording
  };

  const clearHandle = () => {
    setIsRunning(false); // Stop the timer
    setVoice(false); // Stop recording
    setRecordBlobLink(null); // Clear the recorded link
    setElapsedTime(0); // Reset the timer
  };

  return (
    <div className="max-w-sm border py-4 px-6 bg-gray-300 rounded-md shadow-md mt-6">
      <h2 className="text-[20px] font-semibold text-gray-800">Record Pronunciation</h2>
      <AudioTimer
        isRunning={isRunning}
        elapsedTime={elapsedTime}
        setElapsedTime={setElapsedTime}
      />
      <div className="mt-4 flex justify-between items-center text-gray-800">
        {recordBlobLink ? (
          <div
            onClick={clearHandle}
            className="rounded-xl py-1 px-1 font-semibold text-[26px] cursor-pointer"
          >
            ✖
          </div>
        ) : (
          ""
        )}
      </div>
      <ReactMic
        record={voice}
        className="sound-wave w-full h-[100px] mt-4 rounded-md"
        onStop={onStop}
        strokeColor="#000000"
        backgroundColor="white"
      />
      <div className="mt-4 flex justify-between items-center text-gray-800">
        {!voice ? (
          <div
            onClick={startHandle}
            className="rounded-xl py-1 px-1 font-semibold text-[26px] text-gray-800 cursor-pointer"
          >
            ▶
          </div>
        ) : (
          <div
            onClick={stopHandle}
            className="rounded-xl py-1 px-1 font-semibold text-[26px] cursor-pointer"
          >
            ◼
          </div>
        )}
      </div>
      <div className="mt-4 flex justify-between items-center text-gray-800 rounded-md">
        {recordBlobLink ? (
          <audio controls src={recordBlobLink} className="mt-6" />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ReactRecorder;
