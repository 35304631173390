import React, { useState, useEffect, useCallback } from 'react';
import { getDatabase, ref, get, set } from 'firebase/database';
import { auth } from '../config/firebase';
import { useNavigate } from 'react-router-dom';
import './Quiz.css';

const Quiz = () => {
  const [words, setWords] = useState([]);
  const [language, setLanguage] = useState('Kumauni');
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0); // Current quiz score
  const [showResults, setShowResults] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [totalScore, setTotalScore] = useState(0); // Cumulative score from Firebase
  const languages = ['Kumauni'];
  const navigate = useNavigate();

  const fetchWords = useCallback(async () => {
    const db = getDatabase();
    const wordsRef = ref(db, 'words');
    const snapshot = await get(wordsRef);

    if (snapshot.exists()) {
      const wordData = snapshot.val();
      const wordList = Object.entries(wordData).map(([word, value]) => ({
        word,
        correctAnswer: value[language],
      }));

      const shuffledWords = wordList.sort(() => 0.5 - Math.random()).slice(0, 10);
      setWords(shuffledWords);
    }
  }, [language]);

  useEffect(() => {
    if (language) fetchWords();
    if (auth.currentUser) fetchUserScore();
  }, [language, fetchWords]);

  const fetchUserScore = async () => {
    const user = auth.currentUser;
    if (user) {
      const db = getDatabase();
      const scoreRef = ref(db, `user/${user.uid}/score`);
      const snapshot = await get(scoreRef);
      if (snapshot.exists()) {
        setTotalScore(snapshot.val());
      }
    }
  };

  const shuffleOptions = (currentWord, wordList) => {
    const otherWords = wordList.filter(w => w.word !== currentWord.word);
    const randomOptions = otherWords
      .sort(() => 0.5 - Math.random())
      .slice(0, 3)
      .map(w => w.correctAnswer);

    const options = [...randomOptions, currentWord.correctAnswer];
    return options.sort(() => 0.5 - Math.random());
  };

  const handleAnswer = (selectedAnswer) => {
    const newSelectedAnswers = [...selectedAnswers];
    newSelectedAnswers[currentQuestion] = selectedAnswer;
    setSelectedAnswers(newSelectedAnswers);

    if (selectedAnswer === words[currentQuestion].correctAnswer) {
      setScore(score + 1);
    }

    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < words.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowResults(true);
      if (auth.currentUser) updateUserScore(); // Update score in Firebase
    }
  };

  const updateUserScore = async () => {
    const user = auth.currentUser;
    if (user) {
      const db = getDatabase();
      const scoreRef = ref(db, `user/${user.uid}/score`);

      // Calculate new total score
      const updatedTotalScore = totalScore + score;

      try {
        // Update total score in Firebase
        await set(scoreRef, updatedTotalScore);

        // Update state to reflect the new total score
        setTotalScore(updatedTotalScore);
      } catch (error) {
        console.error('Error updating score:', error);
      }
    }
  };

  const handleLanguageSelect = (e) => {
    setLanguage(e.target.value);
  };

  return (
    <div className="min-h-screen flex flex-col items-center bg-gray-100 py-10 px-4 sm:px-6 lg:px-8">
      <h2 className="text-3xl font-semibold mb-5 text-gray-800">Guess the Word in Kumaoni</h2>
      {/* <select
        className="p-2 bg-white border border-gray-300 rounded-md mb-5 w-full sm:w-80"
        onChange={handleLanguageSelect}
        value="Kumaoni"
      >
        <option value="">Select Language</option>
        {languages.map((lang) => (
          <option key={lang} value={lang}>
            {lang}
          </option>
        ))}
      </select> */}

      {!showResults ? (
        words.length > 0 ? (
          <div className="w-full sm:w-80 bg-white shadow-lg p-6 rounded-lg">
            <h3 className="text-xl font-semibold text-gray-700 mb-4">{words[currentQuestion]?.word}</h3>
            <div className="flex flex-col space-y-3">
              {shuffleOptions(words[currentQuestion], words).map((option, index) => (
                <button
                  key={index}
                  className="px-4 py-2 text-white rounded-md hover:bg-blue-600 transition"
                  onClick={() => handleAnswer(option)}
                >
                  {option}
                </button>
              ))}
            </div>
            <p className="mt-4 text-gray-600">Question {currentQuestion + 1} of {words.length}</p>
          </div>
        ) : (
          <p>Loading questions...</p>
        )
      ) : (
        <div className="w-full sm:w-80 bg-white shadow-lg p-6 rounded-lg">
          <h2 className="text-2xl font-semibold text-gray-700 mb-4">Your Score: {score}/{words.length}</h2>
          <h3 className="text-lg font-semibold text-gray-700 mb-2">Review Your Answers:</h3>
          <ul className="space-y-4">
            {words.map((word, index) => (
              <li key={index} className="text-gray-600">
                <strong>Word:</strong> {word.word}<br />
                <strong>Your Answer:</strong> {selectedAnswers[index]}<br />
                {selectedAnswers[index] !== word.correctAnswer && (
                  <span className="text-red-500"> (Incorrect) - <strong>Correct Answer:</strong> {word.correctAnswer}</span>
                )}
              </li>
            ))}
          </ul>
          <button
            className="mt-6 px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition"
            onClick={() => navigate('/')}
          >
            Return Home
          </button>
        </div>
      )}
    </div>
  );
};

export default Quiz;
