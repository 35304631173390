import React, { useState } from "react";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getDatabase, ref as dbRef, update } from "firebase/database";
import ReactRecorder from "./ReactRecorder";

const AudioRecorder = () => {
  const [wordId, setWordId] = useState(""); // State for user input
  const [recordedBlob, setRecordedBlob] = useState(null); // State to store the recorded audio blob

  const uploadAudio = async (audioBlob) => {
    if (!wordId) {
      alert("Please enter a word ID before saving!");
      return;
    }
    const storage = getStorage();
    const audioRef = ref(storage, `pronunciations/${wordId}.webm`);
    await uploadBytes(audioRef, audioBlob);

    const downloadURL = await getDownloadURL(audioRef);
    saveAudioURLToDatabase(downloadURL);
  };

  const saveAudioURLToDatabase = (url) => {
    const database = getDatabase();
    const updates = {};
    const metadata = {
      url: url,
      // uploadedBy: "testUserId", // Replace with the current user's ID if using authentication
      // uploadedAt: new Date().toISOString(),
    };
    updates[`words/${wordId}/pronunciation`] = metadata;
    update(dbRef(database), updates);
    alert("Pronunciation saved successfully!");
  };

  const handleSave = () => {
    if (!recordedBlob) {
      alert("No audio recorded yet!");
      return;
    }
    const audioBlob = recordedBlob.blob;
    uploadAudio(audioBlob);
  };

  const handleRecordingComplete = (blob) => {
    console.log("Recording complete:", blob); // Debugging
    setRecordedBlob(blob); // Store the recorded audio blob
  };
  

  return (
    <>
      <h3 className="text-xl p-3 font-bold mb-4 text-gray-800 text-[20px]">
        Upload Pronunciation for Kumaouni Translations
      </h3>
    <div className="p-1 bg-gray-50 rounded-md pb-6 max-w-md">

      {/* Input field for wordId */}
      <div className="mb-3 p-2 pr-10">
        <label className="block" htmlFor="wordId">
          Word 
        </label>
        <input
          id="wordId"
          type="text"
          value={wordId}
          onChange={(e) => setWordId(e.target.value)}
          className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-black-500"
          placeholder="Enter Word"
        />
      </div>
      {/* ReactRecorder to record audio */}
      <div className="mb-3">
        <ReactRecorder onSave={handleRecordingComplete} />
      </div>
      {/* Save button */}
      <button
        onClick={handleSave}
        className="px-4 py-2 bg-black-600 text-white rounded-md hover:bg-black-700 transition duration-200"
      >
        Save
      </button>
    </div>
    </>
  );
};

export default AudioRecorder;
