import React, { useEffect, useState } from 'react';
import { getDatabase, ref as dbRef, onValue } from 'firebase/database';
import {
  CircularProgress,

} from '@mui/material';
import './LiteratureList.css';

export const LiteratureList = () => {
  const [literature, setLiterature] = useState([]);
  const [expandedItems, setExpandedItems] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const itemsPerPage = 6;

  useEffect(() => {
    const database = getDatabase();
    const literatureRef = dbRef(database, 'literature');

    onValue(literatureRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const formattedData = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));
        setLiterature(formattedData);
      }
    });
  }, []);

  const toggleExpand = (id) => {
    setExpandedItems((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const truncateText = (text, limit) => {
    return text.length > limit ? `${text.substring(0, limit)}...` : text;
  };

  // Calculate pagination
  const totalPages = Math.ceil(literature.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const visibleItems = literature.slice(startIndex, startIndex + itemsPerPage);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  //loading spinner
  useEffect(() => {
    const database = getDatabase();
    const literatureRef = dbRef(database, 'literature');
  
    onValue(literatureRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const formattedData = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));
        setLiterature(formattedData);
      }
      setLoading(false);
    });
  }, []);

  return (
    <div className="literature-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h2 className="literature-title">Literature Section</h2>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <div className="literature-list">
          {visibleItems.map((item) => (
            <div key={item.id} className="literature-card">
              <h3 className="literature-name">{item.name}</h3>
              <p className="literature-description">
                {expandedItems[item.id]
                  ? item.description
                  : truncateText(item.description, 200)}{' '}
                <span
                  className="toggle-text"
                  onClick={() => toggleExpand(item.id)}
                >
                  {expandedItems[item.id] ? 'Show Less' : 'Show More'}
                </span>
              </p>
              <a
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                className="literature-link"
              >
                View PDF
              </a>
            </div>
          ))}
        </div>
      )}
  
      <div className="pagination-controls">
        <button
          className="pagination-button"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="pagination-info">
          Page {currentPage} of {totalPages}
        </span>
        <button
          className="pagination-button"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
  
};

export default LiteratureList;
